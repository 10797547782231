export function getPrefixedDataSet(prefix, $domNode) {
    prefix = lowerCaseFirstLetter(prefix.split('-').map(upperCaseFirstLetter).join(''));

    return Object.entries($domNode.data() || {})
        .filter(([key]) => key.startsWith(prefix))
        .map(([key, value]) => ([lowerCaseFirstLetter(key.replace(prefix, '')), value]))
        .reduce((acc, [key, value]) => ({...acc, [key]: value}), {});
}

function lowerCaseFirstLetter(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
}
function upperCaseFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}