"use strict";

import $ from "jquery";

const defaultSelectors = {
    base: '.js-nav',
    item: '.js-nav__item'
};

export function createInitInScope(selectors = defaultSelectors) {
    if(matchMedia('(max-width: 767px)').matches){
        /* not on mobile */
        return;
    }

    return function ($scope) {
        let $nav = $scope.find(selectors.base),
            $navItems = $scope.find(selectors.item),
            $navItemsLinks = $scope.find(selectors.item).children('a');

        $navItemsLinks.on('keydown', function (evt) {
            if(evt.originalEvent.code === 'Space' || evt.originalEvent.code === 'ArrowDown' || evt.originalEvent.code === 'ArrowUp'){
                evt.preventDefault();
                $(this).closest(selectors.base).find(selectors.item).removeClass('is-open');
                $(this).closest(selectors.item).addClass('is-open');

                $('body').on('keydown', escKeyHandler);
            } else if (evt.originalEvent.code === 'Tab' && !$(this).parents(selectors.item).hasClass('is-open')) {
                $navItems.removeClass('is-open');
                $('body').off('keydown', escKeyHandler);
            }
        });

        function escKeyHandler (evt){
            if(evt.originalEvent.code === 'Escape'){
                $navItems.removeClass('is-open');
                $('body').off('keydown', escKeyHandler);
            }
        }

        return $nav;
    }
}

export const initInScope = createInitInScope();