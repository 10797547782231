export function dateToISOString(date) {
    if (date) {
        if (date instanceof Date){
            return date.toISOString().substring(0, 19);
        } else {
            console.warn('dateToISOString expects a date, but was given ', date);
        }

    }
}

export function ISOStringToDate(dateString) {
    if (dateString) {
        if (typeof dateString === "string") {
            dateString += dateString.includes('Z') ? '' : 'Z';
            return new Date(dateString);
        } else {
            console.warn('ISOStringToDate expects a string, but was given ', date);
        }
    }
}

export function localDateToUTCDate(date) {
    if (date){
        if (date instanceof Date){
            return new Date(date.getTime() - (date.getTimezoneOffset() * 60 * 1000));
        } else {
            console.warn('localDateToUTCDate expects a date, but was given ', date);
        }
    }
}

export function UTCDateToLocalDate(date) {
    if (date) {
        if (date instanceof Date){
            return new Date(date.getTime() + (date.getTimezoneOffset() * 60 * 1000));
        } else {
            console.warn('UTCDateToLocalDate expects a date, but was given ', date);
        }
    }
}

