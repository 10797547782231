"use strict";

export function initInScope ($scope) {
    $scope.find('.js-action-changer').on('submit', function () {
        let newAction = $(this).data('action');

        if (newAction) {
            $(this).attr('action', newAction);
        } else {
            $(this).attr('action', '');
        }
    });
}